import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './OrderDetails.scss';
import { formatTimeInterval } from 'common/utils/time';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import Money from 'common/components/Money/Money';
import { ADDITIONAL_SERVICES_ID_MAP, CurrencyEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import DestinationIcon from 'common/icons/DestinationIcon';
import OriginIcon from 'common/icons/OriginIcon';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import { BrokerOrderDetailsT } from 'broker-admin/store/orders/models';
import { generatePath } from 'react-router';
import { AssetRoutesEnum, PartnersRoutesEnum } from 'broker-admin/constants';
import { PartnerTypeEnum } from 'common/utils/api/models';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import { moneyOperations } from 'common/utils/money';
import RouteIcon from 'common/icons/RouteIcon';
import { convertToKm } from 'common/utils/distance';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import AssetLinkFormatter from 'design-system/components/InfoTable/formatters/AssetLinkFormatter/AssetLinkFormatter';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import AsyncEmissionStandartAdditionalServicesFormatter from 'design-system/components/InfoTable/formatters/AsyncEmissionStandartAdditionalServicesFormatter/AsyncEmissionStandartAdditionalServicesFormatter';
import LinkIfNeedFormatter from 'design-system/components/InfoTable/formatters/LinkIfNeedFormatter/LinkIfNeedFormatter';

type PropsT = {
    order: BrokerOrderDetailsT | null | undefined;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
};

const cx = classNames.bind(styles);

const ADDRESS_SEPARATOR = ', ';

const OrderDetails: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const { order, onOpenUserDetails } = props;

    if (!order) {
        return null;
    }

    const firstShipment = order.shipments?.[0];

    const shipperLink = order?.shipperId
        ? generatePath(PartnersRoutesEnum.partnerDetails, {
              partnerType: PartnerTypeEnum.shipper,
              partnerId: order?.shipperId || 'null',
          })
        : null;

    const trailerLink = firstShipment?.trailer
        ? generatePath(AssetRoutesEnum.trailerDetails, {
              partnerType: PartnerTypeEnum.carrier,
              partnerId: firstShipment?.trailer?.carrierId || 'null',
              trailerId: firstShipment?.trailer?.vehicleId || 'null',
          })
        : null;

    const truckLink = firstShipment?.truck
        ? generatePath(AssetRoutesEnum.truckDetails, {
              partnerType: PartnerTypeEnum.carrier,
              partnerId: firstShipment?.truck?.carrierId || 'null',
              truckId: firstShipment?.truck?.vehicleId || 'null',
          })
        : null;

    const trailerCarrierLink = firstShipment?.trailer
        ? generatePath(PartnersRoutesEnum.partnerDetails, {
              partnerType: PartnerTypeEnum.carrier,
              partnerId: firstShipment?.trailer?.carrierId || 'null',
          })
        : null;

    const truckCarrierLink = firstShipment?.truck
        ? generatePath(PartnersRoutesEnum.partnerDetails, {
              partnerType: PartnerTypeEnum.carrier,
              partnerId: firstShipment?.truck?.carrierId || 'null',
          })
        : null;

    const shipperDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.shipper'),
            value: shipperLink ? (
                <LinkIfNeedFormatter to={shipperLink}>{order?.shipperName}</LinkIfNeedFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const carrierDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.truck-carrier'),
            value: firstShipment?.truck ? (
                <LinkIfNeedFormatter to={truckCarrierLink}>{firstShipment.truck.carrierName}</LinkIfNeedFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.truck'),
            value: (
                <AssetLinkFormatter to={truckLink}>
                    <AssetLabelFormatter
                        model={firstShipment?.truck?.model}
                        plateNumber={firstShipment?.truck?.plateNumber}
                    />
                </AssetLinkFormatter>
            ),
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.trailer-carrier'),
            value: firstShipment.trailer ? (
                <LinkIfNeedFormatter to={trailerCarrierLink}>{firstShipment.trailer.carrierName}</LinkIfNeedFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.trailer'),
            value: (
                <AssetLinkFormatter to={trailerLink}>
                    <AssetLabelFormatter
                        model={firstShipment?.trailer?.model}
                        plateNumber={firstShipment?.trailer?.plateNumber}
                    />
                </AssetLinkFormatter>
            ),
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
    ];

    const priceOffer = firstShipment?.priceOffer || null;

    // @ts-expect-error broker order details is deprecated
    const additionalServiceEnumTypes = order?.additionalServices.map((additionalService) => {
        return additionalService.enum;
    });

    const moveDetails: Array<InfoTableRowT> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.distance'),
            value: (
                <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(priceOffer?.distance)} />
            ),
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            testSelector: 'distance',
        },
        {
            icon: null,
            name: t('common:order-details.columns.trailer-type'),
            value: <SimpleTrailerTypeFormatter trailerDictType={firstShipment?.dictTrailer} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.emissions-class'),
            value: <AsyncEmissionStandartAdditionalServicesFormatter enumTypes={additionalServiceEnumTypes} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const loadDetails: Array<InfoTableRowT | null> = [
        {
            icon: null,
            name: t('common:order-details.columns.load-units'),
            value: (
                <UnitTypeCount
                    type={getLoadUnitType(firstShipment?.unitsType)}
                    count={firstShipment?.maxNumberOfUnits}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.cargo-weight'),
            value: <UnitTypeCount count={firstShipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        firstShipment?.dictTrailer?.reefer
            ? {
                  icon: null,
                  name: t('common:order-details.columns.temperature'),
                  value: (
                      <TemperatureModeFormatter
                          lowestTemperature={firstShipment?.lowestTemperature}
                          highestTemperature={firstShipment?.highestTemperature}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
              }
            : null,
        {
            icon: null,
            name: t('common:order-details.columns.description'),
            value: firstShipment?.description,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const additionalServicesDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.additional-services-cost'),
            value: (
                <Money
                    amount={moneyOperations.plus(
                        firstShipment?.priceOffer?.additionalServices,
                        firstShipment?.priceOffer?.greenCost,
                        CurrencyEnum.EUR,
                    )}
                    currency={CurrencyEnum.EUR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        // @ts-expect-error broker order details is deprecated
        ...(order?.additionalServices || []).map((additionalService): InfoTableRowT => {
            if (additionalService.additionalServiceId === ADDITIONAL_SERVICES_ID_MAP.EURO6) {
                return {
                    icon: null,
                    name: <AsyncAdditionalServiceFormatter type={additionalService.enum} />,
                    isBoldName: false,
                    value: <Money amount={firstShipment?.priceOffer?.greenCost} currency={CurrencyEnum.EUR} />,
                    emptyValue: null,
                    isBoldValue: true,
                    testSelector: additionalService.enum,
                };
            }

            return {
                icon: null,
                name: <AsyncAdditionalServiceFormatter type={additionalService.enum} />,
                isBoldName: false,
                value: <Money amount={additionalService.cost} currency={CurrencyEnum.EUR} />,
                emptyValue: null,
                isBoldValue: true,
            };
        }),
    ];

    const priceDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.offer'),
            isBoldName: false,
            value: <Money amount={firstShipment?.priceOffer?.totalPrice} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.line-haul-cost'),
            isBoldName: false,
            value: <Money amount={firstShipment?.priceOffer?.lineHaul} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.trip-cost'),
            isBoldName: false,
            value: <Money amount={firstShipment?.priceOffer?.toll} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.fuel-cost'),
            value: <Money amount={firstShipment?.priceOffer?.fuel} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        ...additionalServicesDetails,
        {
            icon: null,
            name: t('common:order-details.columns.tranziit-service-fee-cost'),
            value: <Money amount={firstShipment?.priceOffer?.tranziitServiceFee} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.layover-cost'),
            value: <Money amount={firstShipment?.priceOffer?.layover} currency={CurrencyEnum.EUR} />,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
        },
    ];

    const originDetails: Array<InfoTableRowT> = [
        {
            icon: <OriginIcon />,
            name: t('common:order-details.columns.origin'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={firstShipment?.origin}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-company-name'),
            value: firstShipment?.originContact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.date-time'),
            value: formatTimeInterval(firstShipment?.pickupDockingHoursFrom, firstShipment?.pickupDockingHoursTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-name'),
            value: firstShipment?.originContact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-phone'),
            value: firstShipment?.originContact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-email'),
            value: firstShipment?.originContact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-comment'),
            value: firstShipment?.originContact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const destinationDetails: Array<InfoTableRowT> = [
        {
            icon: <DestinationIcon />,
            name: t('common:order-details.columns.destination'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={firstShipment?.destination}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-company-name'),
            value: firstShipment?.destinationContact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.date-time'),
            value: formatTimeInterval(firstShipment?.dropOffDockingHoursFrom, firstShipment?.dropOffDockingHoursTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-name'),
            value: firstShipment?.destinationContact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-phone'),
            value: firstShipment?.destinationContact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-email'),
            value: firstShipment?.destinationContact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.contact-comment'),
            value: firstShipment?.destinationContact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const creationDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.columns.id'),
            value: order?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.po-number'),
            value: null,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.created-on'),
            value: <DateFormatter date={order?.createdOn} format="DD MMM YYYY" />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:order-details.columns.author'),
            value: (
                <UserLinkFormatter
                    fullName={order?.createdByName}
                    userId={order?.createdById}
                    byBroker={order?.createdByBroker}
                    onOpenUserDetails={(userId) => {
                        onOpenUserDetails({
                            partnerType: PartnerTypeEnum.shipper,
                            partnerId: order?.shipperId || '-',
                            userId: order?.createdById || null,
                        });
                    }}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    return (
        <>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstShipment?.origin}
                pickupDockingHoursFrom={firstShipment?.pickupDockingHoursFrom}
                pickupDockingHoursTo={firstShipment?.pickupDockingHoursTo}
                destination={firstShipment?.destination}
                dropOffDockingHoursFrom={firstShipment?.dropOffDockingHoursFrom}
                dropOffDockingHoursTo={firstShipment?.dropOffDockingHoursTo}
            />
            <InfoTable shouldRenderIcons className={cx('table')} rows={moveDetails} />
            <InfoTable shouldRenderIcons className={cx('table', 'table--shipper')} rows={shipperDetails} />
            <InfoTable shouldRenderIcons className={cx('table')} rows={carrierDetails} />
            <InfoTable shouldRenderIcons className={cx('table')} rows={priceDetails} isCollapsable />
            <InfoTable shouldRenderIcons className={cx('table')} rows={loadDetails} />
            <InfoTable shouldRenderIcons className={cx('table')} rows={creationDetails} />
            <InfoTable shouldRenderIcons className={cx('table')} rows={originDetails} isCollapsable />
            <InfoTable
                shouldRenderIcons
                className={cx('table', 'table--isLast')}
                rows={destinationDetails}
                isCollapsable
            />
        </>
    );
});

export default OrderDetails;
