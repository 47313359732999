import * as React from 'react';
import { useCallback, useMemo } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './BidDetails.scss';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import BigRadioIcon from 'common/icons/BigRadioIcon';
import { logWarning } from 'common/utils/logger';
import { SpotBidT } from 'broker-admin/utils/api/spot-broker-tranziit/models';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { urlFactory as brokerUrlFactory } from 'broker-admin/utils/urls';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import SpotBidStatusPill from 'common/components/status-pill/SpotBidStatusPill/SpotBidStatusPill';
import ExpandIcon from 'common/icons/ExpandIcon';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import AccountIcon from 'common/icons/AccountIcon';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import DateIntervalFormatter from 'design-system/components/InfoTable/formatters/DateIntervalFormatter/DateIntervalFormatter';
import CommentIcon from 'common/icons/CommentIcon';
import CommentFormatter from 'design-system/components/InfoTable/formatters/CommentFormatter/CommentFormatter';
import { useTranslation } from 'react-i18next';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import IconTrigger from 'design-system/components/IconTrigger/IconTrigger';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import scrollIntoView from 'scroll-into-view';
import MoneyDiff from 'common/components/MoneyDiff/MoneyDiff';

type PropsT = {
    className?: string;
    isDisabled?: boolean;
    isSelected?: boolean;
    bid: SpotBidT | null | undefined;
    onSelect: (bid: SpotBidT) => void;
    estimateSpotRequestPrice: number | null;
    onOpenUserDetails: (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => void;
};

const cx = classNames.bind(styles);

const BidDetails: React.FC<PropsT> = React.memo((props) => {
    const { className, bid, onSelect, isSelected, isDisabled, estimateSpotRequestPrice, onOpenUserDetails } = props;

    const ref = React.createRef<HTMLDivElement>();

    const [isOpenedDetails, setIsOpenedDetails] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const toggleOpenedDetailsClick = () => {
        setIsOpenedDetails(!isOpenedDetails);

        const shouldScroll = !isOpenedDetails;
        if (shouldScroll) {
            if (!ref.current) {
                return;
            }

            scrollIntoView(ref.current, {
                time: 300,
            });
        }
    };

    const handleSelect = () => {
        if (isDisabled) {
            toggleOpenedDetailsClick();
            return;
        }

        if (!bid) {
            logWarning('empty bid!');
            return;
        }

        if (isSelected) {
            toggleOpenedDetailsClick();
            return;
        }

        onSelect(bid);
    };

    const isClickable = !isDisabled;

    const carrierId = bid?.carrier?.id;

    const handleOpenUserDetails = useCallback(
        (userId: UserIdT) => {
            onOpenUserDetails({
                userId,
                partnerType: PartnerTypeEnum.carrier,
                partnerId: bid?.carrier?.id || '-',
            });
        },
        [bid],
    );

    const bidDetails: Array<InfoTableRowT | null> = useMemo(
        () => [
            {
                icon: (
                    <AccountIcon
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
                name: t('spot-request-details.bids.details.placed-by'),
                value: (
                    <UserLinkFormatter
                        fullName={bid?.createdBy?.fullName}
                        userId={bid?.createdBy?.id}
                        onOpenUserDetails={handleOpenUserDetails}
                    />
                ),
                isBoldValue: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-user',
            },
            {
                icon: <TimeWindowIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />,
                name: t('spot-request-details.bids.details.valid'),
                value: (
                    <DateIntervalFormatter
                        startDate={bid?.bidDate}
                        endDate={bid?.lifeTime}
                        format="D MMM YYYY, HH:mm"
                    />
                ),
                isBoldValue: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-date',
            },
            bid?.comment
                ? {
                      icon: (
                          <CommentIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />
                      ),
                      name: t('spot-request-details.bids.details.comment'),
                      value: <CommentFormatter comment={bid.comment} />,
                      isBoldValue: true,
                      emptyValue: t('common:info-table.placeholders.empty'),
                      testSelector: 'upload-date',
                  }
                : null,
        ],
        [bid, handleOpenUserDetails],
    );

    const diffPercentage = React.useMemo((): number => {
        if (!estimateSpotRequestPrice || !bid || !bid?.bidPrice) {
            return 0;
        }

        return Math.round((bid.bidPrice / estimateSpotRequestPrice - 1) * 100);
    }, [estimateSpotRequestPrice, bid]);

    if (!bid) {
        return null;
    }

    return (
        <div
            className={cs(
                cx('inner', {
                    'inner--isClickable': isClickable,
                }),
                className,
            )}
            onClick={handleSelect}
            ref={ref}
        >
            <div
                className={cx('row', 'head', {
                    'head--isOpened': isOpenedDetails,
                    'head--isClickable': !isDisabled,
                })}
            >
                <div className={cx('column__icon')}>
                    <BigRadioIcon isSelected={isSelected} isDisabled={isDisabled} />
                </div>
                <div className={cx('column__main')}>
                    <div className={cx('main')}>
                        {bid.bidPrice ? (
                            <>
                                <MoneyDiff value={bid.bidPrice} baseValue={estimateSpotRequestPrice}>
                                    {(diff) => (
                                        <ColoredDiff isInverted diff={diff}>
                                            <Money
                                                className={cx('main__money')}
                                                amount={bid.bidPrice}
                                                currency={CurrencyEnum.EUR}
                                            />
                                        </ColoredDiff>
                                    )}
                                </MoneyDiff>
                                {!!diffPercentage && (
                                    <PillLabel
                                        className={cx('main__diff')}
                                        theme={
                                            diffPercentage > 0
                                                ? PillLabelThemeEnum.tomatoRed
                                                : PillLabelThemeEnum.brandAccent
                                        }
                                        isSymmetrical
                                    >
                                        {diffPercentage > 0 ? '+' : ''}
                                        {diffPercentage}%
                                    </PillLabel>
                                )}
                                <ClickInterceptorLabel>
                                    <Tooltip
                                        position={TooltipPositionEnum.topCenter}
                                        theme={TooltipThemeEnum.black}
                                        tooltipNode={
                                            <TooltipContent theme={TooltipContentThemeEnum.black}>
                                                {t('spot-request-details.bids.expand')}
                                            </TooltipContent>
                                        }
                                    >
                                        {(isShow) => (
                                            <IconTrigger isFocused={isShow} onClick={toggleOpenedDetailsClick}>
                                                {() => (
                                                    <ExpandIcon
                                                        fillColor={StyleGuideColorsEnum.brandAccent}
                                                        isInvert={isOpenedDetails}
                                                    />
                                                )}
                                            </IconTrigger>
                                        )}
                                    </Tooltip>
                                </ClickInterceptorLabel>
                            </>
                        ) : (
                            <div className={cx('main__money')}>{t('spot-request-details.bids.details.no-bid')}</div>
                        )}
                    </div>
                    {carrierId && (
                        <div className={cx('link')}>
                            <Link
                                to={brokerUrlFactory.partnerDetails(PartnerTypeEnum.carrier, carrierId)}
                                theme={LinkThemeEnum.boldBrandDark}
                                size={LinkSizeEnum.medium}
                            >
                                {bid.carrier?.name}
                            </Link>
                        </div>
                    )}
                </div>
                <div className={cx('column__status')}>
                    {!!bid.match && (
                        <PillLabel
                            className={cx('match-pill')}
                            theme={PillLabelThemeEnum.grey}
                            testSelector="spot-bid-match"
                            isSymmetrical
                        >
                            {t('spot-request-details.bids.match')}
                        </PillLabel>
                    )}
                    <SpotBidStatusPill isSymmetrical status={bid.status} />
                </div>
            </div>
            {isOpenedDetails && (
                <div className={cx('body')}>
                    <InfoTable shouldRenderIcons rows={bidDetails} testSelector="bid-details" />
                </div>
            )}
        </div>
    );
});

export default BidDetails;
